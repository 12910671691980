































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import LoginFormHeaderLogo from '@/views/auth/components/LoginFormHeaderLogo.vue';
import { ApiFacade } from '@/services/ApiFacade';
import { requiredValidator } from '@/services/defaultFormValidators';
import { VForm } from '@/types/VForm';
import LoadingContent from '@/components/LoadingContent.vue';

@Component({
  components: {
    LoadingContent,
    LoginFormHeaderLogo,
  },
})
export default class FinishPasswordResetView extends Vue {
  password: string = '';
  token: string = '';
  isTokenValid: boolean | 'unknown' = 'unknown';
  showPassword: boolean = false;

  redirectTimeout: number = 0;
  isPasswordChanged: boolean = false;

  redirectSecs: number = 10;

  @Inject() apiFacade!: ApiFacade;
  error: Error | null = null;
  isValid: boolean = true;
  isSending: boolean = false;
  isLoading: boolean = false;

  $refs!: {
    newPasswordForm: VForm;
  };

  rules = {
    required: requiredValidator,
  };

  get errorMessage() {
    return this.error?.message || '';
  }

  async mounted() {
    this.token = this.$route.params.tokenId;
    this.isLoading = true;
    try {
      this.isTokenValid = await this.apiFacade.checkRestorePasswordToken(this.token);
    } finally {
      this.isLoading = false;
    }
  }

  async submit() {
    if (!this.$refs.newPasswordForm.validate()) {
      return;
    }
    try {
      this.isSending = true;
      this.error = null;
      const restoreResult = await this.apiFacade.newPassword(this.token, this.password);

      if (restoreResult) {
        this.isPasswordChanged = true;
        this.redirectTimeout = setTimeout(() => {
          this.$router.push({ name: 'login' });
        }, this.redirectSecs * 1000);
        return;
      }

      throw new Error('Ошибка. Попробуйте еще позднее.');
    } catch (err) {
      this.error = err;
      console.error('Login error', err);
    } finally {
      this.isSending = false;
    }
  }

  destroyed() {
    clearTimeout(this.redirectTimeout);
  }
}
